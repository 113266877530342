@use "sass:math";

@import "sweetalert2/src/variables";

// Theme variables
$material-ui-blue: #2196f3;
$material-ui-disabled: #808080;
$material-ui-white: #fff;
$material-ui-slider-track-height: 2px;
$material-ui-slider-thumb-size: 16px;
// Variables to be added to Sweetalert2
$swal2-button-focus-background: rgba($material-ui-blue, 0.3);
$swal2-close-button-font-family: arial;

// override SASS variables here
// CONFIRM BUTTON
$swal2-confirm-button-border: 0;
$swal2-confirm-button-border-radius: 0;
$swal2-confirm-button-background-color: rgba(43, 21, 21, 0);
$swal2-confirm-button-color: $material-ui-blue;
$swal2-confirm-button-focus-box-shadow: none;

// DENY BUTTON
$swal2-deny-button-border: 0;
$swal2-deny-button-border-radius: 0;
$swal2-deny-button-color: $material-ui-blue;
$swal2-deny-button-focus-box-shadow: none;

// CANCEL BUTTON
$swal2-cancel-button-border: 0;
$swal2-cancel-button-border-radius: 0;
$swal2-cancel-button-color: $material-ui-blue;
$swal2-cancel-button-focus-box-shadow: none;

// BUTTONS
$swal2-button-darken-hover: rgba($material-ui-blue, 0.1);
$swal2-button-darken-active: rgba($material-ui-blue, 0.3);
$swal2-button-focus-box-shadow: none;

// ACTIONS
$swal2-actions-justify-content: flex-end;
$swal2-actions-padding: 0 1em;

// CLOSE BUTTON
$swal2-close-button-border-radius: 50%;
$swal2-close-button-hover-background: rgb(242, 242, 242);
$swal2-close-button-hover-color: $swal2-close-button-color;

// CHECKBOX
$checkbox-size-px: 18;
$checkbox-left-px: -2;

// Temporary / Permanent workarounds
// Input validation error
.swal2-popup {

  &.swal2-modal,
  &.swal2-toast {
    width: 600px;

    &.blue-dark {
      background: #444444;
      color: #fff;

      & option {
        background: #777777;
        color: rgb(237, 235, 235);
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
      }

      & button {
        &.swal2-confirm {
          background-color: #0277bd;

          &:focus {
            box-shadow: 0 0 0 2px #024c77;
          }
        }

        &.swal2-deny {
          background-color: #ffd740;
        }

        &.swal2-cancel {
          background-color: #ffd740;
        }
      }
    }

    &.green-dark {
      background: #444444;
      color: #fff;

      .swal2-progress-steps {
        .swal2-active-progress-step {
          background: #81c784;
        }
      }

      & option {
        background: #777777;
        color: rgb(237, 235, 235);
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
      }

      & button {
        &.swal2-confirm {
          background-color: #81c784;

          &:focus {
            box-shadow: 0 0 0 2px #6dac70;
          }
        }

        &.swal2-deny {
          background-color: #ffd740;
        }

        &.swal2-cancel {
          background-color: #ffd740;
        }
      }
    }

    &.dvv-dark {
      background: #444444;
      color: #fff;

      .swal2-progress-steps {
        .swal2-active-progress-step {
          background: #004481;
        }
      }

      & option {
        background: #777777;
        color: rgb(237, 235, 235);
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
      }

      & button {
        &.swal2-confirm {
          background-color: #004481;

          &:focus {
            box-shadow: 0 0 0 2px #0055a0;
          }
        }

        &.swal2-deny {
          background-color: #ebb700;
        }

        &.swal2-cancel {
          background-color: #ebb700;
        }
      }
    }

    &.pink-dark {
      background: #444444;
      color: #fff;

      .swal2-progress-steps {
        .swal2-active-progress-step {
          background: #d81b60;
        }
      }

      & option {
        background: #777777;
        color: rgb(237, 235, 235);
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
      }

      & button {
        &.swal2-confirm {
          background-color: #d81b60;

          &:focus {
            box-shadow: 0 0 0 2px #b1114c;
          }
        }

        &.swal2-deny {
          background-color: #546e7a;
        }

        &.swal2-cancel {
          background-color: #546e7a;
        }
      }
    }

    &.indigo-light {
      .swal2-progress-steps {
        .swal2-active-progress-step {
          background: #004481;
        }
      }

      & button {
        &.swal2-confirm {
          background-color: #004481;

          &:focus {
            box-shadow: 0 0 0 2px #015cac;
          }
        }

        &.swal2-deny {
          background-color: #ff4081;
        }

        &.swal2-cancel {
          background-color: #ff4081;
        }
      }
    }

    &.teal-light {
      .swal2-progress-steps {
        .swal2-active-progress-step {
          background: #00695c;
        }
      }

      & button {
        &.swal2-confirm {
          background-color: #00695c;

          &:focus {
            box-shadow: 0 0 0 2px #019c8a;
          }
        }

        &.swal2-deny {
          background-color: #ffd740;
        }

        &.swal2-cancel {
          background-color: #ffd740;
        }
      }
    }

    &.red-light {
      .swal2-progress-steps {
        .swal2-active-progress-step {
          background: #c62828;
        }
      }

      & button {
        &.swal2-confirm {
          background-color: #c62828;

          &:focus {
            box-shadow: 0 0 0 2px #a02020;
          }
        }

        &.swal2-deny {
          background-color: #43a047;
        }

        &.swal2-cancel {
          background-color: #43a047;
        }
      }
    }

    &.dvv-light {
      .swal2-progress-steps {
        .swal2-active-progress-step {
          background: #004481;
        }
      }

      & button {
        &.swal2-confirm {
          background-color: #004481;

          &:focus {
            box-shadow: 0 0 0 2px #005fb3;
          }
        }

        &.swal2-deny {
          background-color: #ebb700;
        }

        &.swal2-cancel {
          background-color: #ebb700;
        }
      }
    }

    .swal2-html-container {
      margin: 0px;

      .swal2-checkbox {
        input {
          position: relative;
          left: 30px;
          width: 40px;
          height: 40px;
          transition: opacity 0.3s, transform 0.2s;
          border-radius: 50%;
          outline: none;
          opacity: 0;
          background-color: $material-ui-blue;
          box-shadow: none;
          appearance: none;
          pointer-events: none;

          &:checked,
          &:indeterminate {
            background-color: $material-ui-blue;
          }

          &:checked+span {
            &::before {
              border-color: $material-ui-blue;
              background-color: $material-ui-blue;
            }

            &::after {
              border-color: $material-ui-white;
            }
          }

          &:indeterminate+span {
            &::before {
              border-color: $material-ui-blue;
              background-color: $material-ui-blue;
            }

            &::after {
              transform: translate($checkbox-left-px * 2px,
                  math.div($checkbox-size-px, 6) * 1px);
              border-left: 0;
              border-color: $material-ui-blue;
            }
          }

          &:active {
            transform: scale(0);
            transition: transform 0s, opacity 0s;
            opacity: 1;

            +span {
              &::before {
                border-color: $material-ui-blue;
              }
            }
          }

          &:checked {
            &:active+span {
              &::before {
                border-color: transparent;
                background-color: $material-ui-blue;
              }
            }
          }

          &:focus {
            opacity: 0.12;
          }
        }

        span {
          &::before {
            content: "";
            position: relative;
            top: 1px;
            left: $checkbox-left-px * 1px;
            box-sizing: border-box;
            width: $checkbox-size-px * 1px;
            height: $checkbox-size-px * 1px;
            float: left;
            transition: border-color 0.2s, background-color 0.2s;
            border: solid 2px;
            border-radius: 2px;
            border-color: $material-ui-disabled;
            vertical-align: top;
          }

          &::after {
            content: "";
            position: relative;
            left: (-$checkbox-size-px + $checkbox-left-px) * 1px;
            width: 10px;
            height: -$checkbox-left-px * 2px;
            float: left;
            transform: translate(math.div($checkbox-size-px, 6) * 1px,
                -$checkbox-left-px * 2px) rotate(-45deg);
            /* stylelint-disable-line */
            border: solid 2px transparent;
            border-top: 0;
            border-right: 0;
          }
        }

        &:hover {
          >input {
            opacity: 0.04;

            &:focus {
              opacity: 0.16;
            }
          }
        }
      }

      .swal2-select {
        border-top-width: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
        width: 330px;
        padding: 0 15px;
      }

      .swal2-label {
        width: 160px;
        height: 22px;
        padding-top: 5px;
      }

      .swal2-input {
        border-top-width: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
        width: auto;
        padding: 0 15px;
        height: 30px;

        &.swal2-inputerror {
          background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20height%3D%2232%22%20style%3D%22overflow%3Avisible%3Benable-background%3Anew%200%200%2032%2032%22%20viewBox%3D%220%200%2032%2032%22%20width%3D%2232%22%20xml%3Aspace%3D%22preserve%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cg%3E%3Cg%20id%3D%22Error_1_%22%3E%3Cg%20id%3D%22Error%22%3E%3Ccircle%20cx%3D%2216%22%20cy%3D%2216%22%20id%3D%22BG%22%20r%3D%2216%22%20style%3D%22fill%3A%23D72828%3B%22%2F%3E%3Cpath%20d%3D%22M14.5%2C25h3v-3h-3V25z%20M14.5%2C6v13h3V6H14.5z%22%20id%3D%22Exclamatory_x5F_Sign%22%20style%3D%22fill%3A%23E6E6E6%3B%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
          background-repeat: no-repeat;
          background-position: right 3% top 50%;
          background-size: 1em auto, 100%;
        }

        margin: 2px;
      }

      .sweet-container {
        display: grid !important;
        grid-template-columns: 160px 330px;
        gap: 5px;
        padding-left: 65px;
      }

      .checkbox-container {
        display: grid !important;
        grid-template-columns: 70px 200px;
        margin-left: 7rem;
        gap: 5px;
      }

      .checkbox-item {
        text-align: start;
      }

      // Select element arrow

      // Radio button
      .swal2-radio {
        input {
          &[type="radio"] {
            opacity: 0; // hiding the radio input
          }
        }

        label {
          position: relative; // Create a new containing block
        }

        .swal2-label {
          padding-left: 0.2em;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 18px;
            height: 18px;
            border: 2px solid $material-ui-disabled;
            border-radius: 100%;
          }
        }
      }

      [type="radio"] {
        &:hover+.swal2-label {
          &::before {
            border: 2px solid $material-ui-blue;
          }
        }

        &:checked+.swal2-label {
          &::before {
            border: 2px solid $material-ui-blue;
          }

          &::after {
            content: "";
            position: absolute;
            top: 5px;
            left: 5px;
            width: 12px;
            height: 12px;
            border-radius: 100%;
            background: $material-ui-blue;
          }
        }
      }
    }
  }
}

// Disabled (actions) buttons
.swal2-styled {
  &[disabled] {
    opacity: 1;
    color: $material-ui-disabled;
  }

  &:focus {
    background-color: $swal2-button-focus-background;
  }
}

// Close button additional style
.swal2-close {
  font-family: $swal2-close-button-font-family;
}

// Mixin for range / slider styling
@mixin slider-track {
  height: $material-ui-slider-track-height;
  background: lighten($material-ui-blue, 30%);
}

@mixin slider-thumb {
  width: $material-ui-slider-thumb-size;
  height: $material-ui-slider-thumb-size;
  margin-top: -1 * math.div($material-ui-slider-thumb-size, 2) + 1;
  /* stylelint-disable-line */
  border: 0;
  border-radius: 50%;
  background: $material-ui-blue;
  appearance: none;
}

[type="range"] {
  appearance: none;

  &:focus {
    outline: none;
  }

  // Chrome does not support browser's pseudo elements comma-separated
  // We need to write all styles for each browser engine
  // sass-lint:disable no-vendor-prefixes
  &::-webkit-slider-runnable-track {
    @include slider-track;
  }

  &::-moz-range-track {
    @include slider-track;
  }

  &::-ms-track {
    @include slider-track;
  }

  &::-webkit-slider-thumb {
    @include slider-thumb;
  }

  &::-moz-range-thumb {
    @include slider-thumb;
  }

  &::-ms-thumb {
    @include slider-thumb;
  }

  // There's no equivalent pseudo element for webkit
  &::-moz-range-progress {
    background: $material-ui-blue;
  }
}
