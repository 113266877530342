//Login, Register, Errors box
.box {
    border-radius: 0;
    min-height: 430px;
    .box-header {
      height: 186px;
      .mat-fab {
        margin-bottom: 8px;
      }
      .error {
        font-size: 24px;
        margin-bottom: 25px;
      }
    }
    .box-content {
      position: relative;
      height: $box-height;
      margin-bottom: 0;
      .box-content-inner {
        position: absolute;
        top: -$box-position;
        left: $box-position;
        right: $box-position;
        height: 100%;
      }
      .box-content-header {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 500;
        margin-top: 5px;
        &.server-error {
          margin-bottom: 36px;
        }
      }
      .box-text {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 15px;
        }
      }
      .box-button {
        bottom: 25px;
        width: 150px;
        border-radius: 20px;
        overflow: hidden;
      }
    }
    &.register {
      .box-content {
        height: $register-box-height;
        .box-content-inner {
          height: $register-box-height;
        }
      }
    }
  }
