@use "sass:math";
.brandLogo {
  margin-bottom: 32px;
  height: 75px;
}

// Custom Styles for DVV
h2 {
  font-size: 1.5em;
  padding: 0;
  margin: 0;
}

// Creation Forms
.dialog-form {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
}

.dialog-full-width {
  width: 100%;
}
.banner {
  display: flex;
  flex-direction: row;
}

.teaser {
  flex: 1;
}
.description {
  flex: 4;
}
mat-checkbox[readonly="true"] {
  pointer-events: none !important;
}

//Map Chart
.leaflet-container {
  border-radius: 0 0 5px 5px !important;
  background-color: transparent !important;
}
.no-overflow {
  overflow: hidden !important;
}

// Tables
.mat-header-row {
  height: 30px !important;
  font-weight: 1em;
}

.mat-column-action {
  width: 20px;
  padding-right: 8px;
}
.mat-table > tr > td {
  padding-right: 0.2rem;
}

//recaptcha

.recaptcha-register {
  height: 80px;
}

// extras

.p-bottom-8 {
  padding-bottom: 8px !important;
}

/** Banner */
.banner {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.2)
    ),
    url("./assets/img/banner/banner_iot.jpg");
  /* Set a specific height */
  height: 200px;
  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 3px;
}
.banner > .marketing {
  position: absolute;
  right: 16px;
  top: 16px;
  font-size: 36px;
  line-height: 48px;
  color: #ffffff;
  font-weight: bold;
}
.banner > .marketing > ul {
  list-style: none;
  font-size: 24px;
  line-height: 32px;
}

/** Breadcrumb */
.breadcrumb {
  background-color: transparent !important;
}

/** Menu Anpassungen */

// #main-content {
//   // background-color: $dvv-bg;
// }

/** General Help classes */
.dvvButton {
  position: absolute;
  z-index: 10;
  background-color: #004481;
  bottom: 0;
  right: 0;
  font-size: 32px;
  line-height: 46px;
  width: 175px;
  color: #ffffff;
  text-align: center;
}

.pointer {
  cursor: pointer !important;
}

/* News Blocks */
.success {
  color: green;
  line-height: 96px;
  font-size: 96px;
}
.text-muted {
  color: #777777 !important;
}

.toast-container,
.toast {
  width: 370px !important;
}
.instance-spinner {
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
}
.gridster {
  padding: 0px !important;
  .gridster-item {
    .chart-title-holder {
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      height: 35px;
      width: 101%;
      display: flex;
      justify-content: center;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .chart-title-holder > h3 {
      font-weight: normal;
      font-size: 16px;
      padding: 4px 0;
    }
    .chart-container {
      height: calc(100% - 37px);
      width: calc(100% - 1px);
      min-height: 180px;
    }
    .chart-container-holder {
      width: 100%;
      height: 99%;
    }
    .chart-button {
      position: absolute;
      right: 0px;
      top: -3px;
    }
  }
  &.mobile {
    .gridster-item {
      &.widget {
        min-height: 0px;
      }
      min-height: 200px;
    }
  }
}
::ng-deep .ngx-json-viewer {
  overflow: auto !important;
}
// #####  MATERIAL #######

.mat-accordion .mat-expansion-panel {
  margin: 2px;
}
.mat-button-toggle {
  /*background-color: #ffffff;*/
  .mat-button-toggle-label {
    flex: 1 1 100%;
    box-sizing: border-box;
    align-items: center;
    flex-direction: row;
    display: flex;

    .mat-button-toggle-label-content {
      text-align: center;
      flex: 1 1 100%;
    }
  }
  label {
    width: 100%;
    text-align: center;
  }
}
.mat-datepicker-content {
  box-shadow: inherit !important;
}
// .mat-select-panel {
//   margin-top: 45px;
// }
.codes-container {
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
}
.codes-item {
  border: 1px solid rgb(202 202 202);
  padding: 10px;
  font-size: 19px;
  text-align: center;
}

// #####  MATERIAL #######
.dark-sweetalert {
  color: white !important;
}
:root {
  --map-tiles-filter: brightness(0.6) invert(1) contrast(2) hue-rotate(200deg)
    saturate(0.3) brightness(0.6);
}
