@use "@angular/material" as mat;
@import "@angular/material/theming";
@include mat.core();
@import "theme-reset";

.app {
  &.indigo-light {
    @import "skins/indigo-light";
    @include mat.all-component-themes($indigo-light-theme);
    @include theme-reset($indigo-light-theme);
  }
  &.teal-light {
    @import "skins/teal-light";
    @include mat.all-component-themes($teal-light-theme);
    @include theme-reset($teal-light-theme);
  }
  &.red-light {
    @import "skins/red-light";
    @include mat.all-component-themes($red-light-theme);
    @include theme-reset($red-light-theme);
  }
  &.blue-dark {
    @import "skins/blue-dark";
    @include mat.all-component-themes($blue-dark-theme);
    @include theme-reset($blue-dark-theme);
    @media (prefers-color-scheme: dark) {
      .map-tiles {
        filter: var(--map-tiles-filter, none);
      }
    }
  }
  &.green-dark {
    @import "skins/green-dark";
    @include mat.all-component-themes($green-dark-theme);
    @include theme-reset($green-dark-theme);
    @media (prefers-color-scheme: dark) {
      .map-tiles {
        filter: var(--map-tiles-filter, none);
      }
    }
  }
  &.pink-dark {
    @import "skins/pink-dark";
    @include mat.all-component-themes($pink-dark-theme);
    @include theme-reset($pink-dark-theme);
    @media (prefers-color-scheme: dark) {
      .map-tiles {
        filter: var(--map-tiles-filter, none);
      }
    }
  }
  &.dvv-dark {
    @import "skins/dvv-dark";
    @include mat.all-component-themes($dvv-dark-theme);
    @include theme-reset($dvv-dark-theme);
    @media (prefers-color-scheme: dark) {
      .map-tiles {
        filter: var(--map-tiles-filter, none);
      }
    }
  }
  &.dvv-light {
    @import "skins/dvv-light";
    @include mat.all-component-themes($dvv-light-theme);
    @include theme-reset($dvv-light-theme);
  }
}
