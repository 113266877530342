$font-family: "Geogrotesque-Regular", Arial, Helvetica, sans-serif !important;

$toolbar-height: 56px;
$horizontal-menu-height: $toolbar-height;

$sidenav-width: 250px;
$sidenav-user-block-height: 156px;
$mini-sidenav-user-block-height: 100px;

$flex-distance: 8px;
$inner-sidenav-content-padding: $flex-distance;

$dark-primary-text: #003300;
$light-primary-text: #ffffff;
// DVV
// $primary: #004481;
// $dvv-bg: #f0f3f4;

$dvv-primary: (
  100: #00458173,
  200: #004481,
  500: #004481,
  800: #004481,
  900: #004481,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$dvv-accent: (
  100: #ebb700,
  200: #ebb700,
  500: #ebb700,
  800: #ebb700,
  900: #ebb700,
  A200: #ebb700,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

//Login, Register, Errors box
$box-height: 230px;
$box-position: 44px;
$register-box-height: 320px;
