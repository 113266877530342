//mailbox
.mail-body {
    p {
      margin-bottom: 1rem;
    }
    ul {
      margin: 0 16px;
    }
    .ql-container.ql-snow {
      min-height: 120px;
      margin-bottom: 10px;
    }
  }