html,
body {
  height: 100%;
}
body {
  font-family: $font-family;
  font-size: 14px;
  line-height: 1.5;
  overflow-x: hidden;
  // background-color: #f0f3f4;
}
* {
  margin: 0;
  padding: 0;
}
a {
  outline: none;
  text-decoration: none;
}
.h-100 {
  height: 100% !important;
}
.w-100 {
  width: 100% !important;
}
.w-100p {
  width: 100px !important;
}
.w-200p {
  width: 200px !important;
}
.w-max-10p {
  max-width: 10px !important;
}
.w-max-20p {
  max-width: 20px !important;
}
.w-max-100p {
  max-width: 100px !important;
}
.w-min-300p {
  min-width: 300px !important;
}
.w-max-300p {
  max-width: 300px !important;
}
.w-min-600p {
  min-width: 600px !important;
}
.h-min-600p {
  min-height: 600px !important;
}
.h-100p {
  height: 100px;
}
.h-300p {
  height: 300px;
}
.flex-p {
  padding: $flex-distance !important;
}
.flex-p-x {
  padding: 0 $flex-distance !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.5rem;
}
.py-1 {
  padding: 1rem 0;
}
.m-0 {
  margin: 0 !important;
}
.mt-2 {
  margin-top: 2px;
}
.text-center {
  text-align: center;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.transition-2 {
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  transition: 0.2s;
}
.rounded {
  border-radius: 50%;
}
.divider {
  width: 100%;
  height: 1px;
  background: #ccc;
}
.toolbar-dropdown-menu {
  margin-top: 9px;
  border-radius: 0 !important;
  .mat-menu-content {
    padding: 0;
  }
}
.hidden {
  display: none !important;
}
.icon-sm,
.mat-icon.icon-sm {
  font-size: 18px;
  line-height: 18px;
  height: 18px;
  width: 18px;
  vertical-align: middle;
}
.mat-icon.icon-lg {
  font-size: 48px;
  width: 48px;
  height: 48px;
}
.white {
  color: #fff;
}
.bold-text {
  font-weight: bold;
}
.draggable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.table-ico {
  max-height: 28px;
}

.logo {
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 0 8px;
  color: #fff;
}
.mat-raised-button.small-logo {
  font-size: 24px;
  font-weight: bold;
  min-width: 36px;
  padding: 0;
  background-color: #fff !important;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
