@import url("https://fonts.googleapis.com/css?family=Source Sans Pro:300,400,700");
// @import "~@sweetalert2/theme-material-ui/material-ui.scss";
@import "./theme";
@import "./libs-override";
@import "./rtl";
@import "../fonts/material-icons.css";
@import "./sweetalert2-theme.scss";

@import "~leaflet/dist/leaflet.css";
@import "perfect-scrollbar/css/perfect-scrollbar.css";
// @import "~@ng-select/ng-select/themes/material.theme.css";
@import "./base";
@import "../../custom.scss";
