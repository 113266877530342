@use "sass:map";
@use "@angular/material" as mat;
@import "@angular/material/theming";
@import "variables";

@mixin theme-reset($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  .bg {
    text-shadow: 0 0 2px black;
    &-primary {
      background-color: mat.get-color-from-palette($primary);
      color: mat.get-color-from-palette($primary, default-contrast);
    }
    &-warn {
      background-color: mat.get-color-from-palette($warn);
      color: mat.get-color-from-palette($warn, default-contrast);
    }
    &-accent {
      background-color: mat.get-color-from-palette($accent);
      color: mat.get-color-from-palette($accent, default-contrast);
    }
  }

  //dashboard ngx-chart pie grid
  .pie-grid .arc1 {
    opacity: 0.2;
  }
  .bg-accent {
    background-color: mat.get-color-from-palette($accent);
  }
  .color-ascend {
    color: mat.get-color-from-palette($accent) !important;
  }
  .color-warn {
    color: mat.get-color-from-palette($warn) !important;
  }
  .color-primary {
    color: mat.get-color-from-palette($primary) !important;
  }
  .options-key {
    user-select: none;
    min-width: 150px;
    // background-color: $dvv-bg;
    // color: mat.get-color-from-palette($primary);
    box-shadow: inset 0 0 0 1px
      mat.get-color-from-palette($background, background);
    padding: 1px 6px 1px 6px;
    // text-shadow: 2px 1px 2px black;
  }
  .options-value {
    // background: $dvv-bg;
    cursor: text;
    box-shadow: inset 0 0 0 1px
      mat.get-color-from-palette($background, background);
    padding: 1px 6px 1px 6px;
    .options-input {
      // background: $dvv-bg;
      cursor: text;
      color: mat.get-color-from-palette($foreground, text);
      font-weight: bold;
      border: 0;
      // background-color: mat.get-color-from-palette($background, background);
    }
    .options-input:hover {
      background: rgba(mat.get-color-from-palette($primary), 0.4);
    }
  }
  .option-error {
    box-shadow: inset 0 0 3px 1px #ff0000;
  }
  .muted-text {
    color: mat.get-color-from-palette($foreground, secondary-text);
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 10px
      mat.get-color-from-palette($background, background) inset;
    box-shadow: 0 0 0px 10px mat.get-color-from-palette($background, background)
      inset;
    -webkit-text-fill-color: mat.get-color-from-palette($foreground, text);
  }
  .gradient-primary {
    background: mat.get-color-from-palette($primary, lighter);
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.5) 0%,
      mat.get-color-from-palette($primary, lighter) 50%,
      mat.get-color-from-palette($primary) 100%
    );
  }
  .mat-progress-bar-fill::after {
    background: mat.get-color-from-palette($primary);
  }
  .mat-toolbar {
    padding: 0 $flex-distance;
    min-height: $toolbar-height;
  }
  .mat-toolbar-row,
  .mat-toolbar-single-row {
    height: $toolbar-height;
  }
  .mat-raised-button.small-logo {
    color: mat.get-color-from-palette($primary);
  }
  .horizontal-menu {
    background: mat.get-color-from-palette($primary, darker) !important;
  }
  .horizontal-menu-item {
    .horizontal-sub-menu {
      background: mat.get-color-from-palette($primary, darker);
    }
  }
  .breadcrumb {
    a {
      color: mat.get-color-from-palette($primary);
    }
    .breadcrumb-item + .breadcrumb-item:before {
      color: mat.get-color-from-palette($foreground, text);
    }
  }
  .user-block {
    border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
  }
  .active-link {
    border-radius: 0;
    background-color: mat.get-color-from-palette($primary);
    color: #fff;
  }
  .warn-color {
    color: mat.get-color-from-palette($warn) !important;
  }

  /* angular-calendar */
  .cal-day-view .cal-hour-segment:hover,
  .cal-day-view .cal-drag-over .cal-hour-segment,
  .cal-week-view .cal-day-headers .cal-header:hover,
  .cal-week-view .cal-day-headers .cal-drag-over,
  .cal-month-view .cal-cell-row .cal-cell:hover,
  .cal-month-view .cal-cell.cal-has-events.cal-open {
    background-color: rgba(mat.get-color-from-palette($primary), 0.4);
  }
  .cal-month-view .cal-cell-row:hover {
    background-color: rgba(mat.get-color-from-palette($primary), 0.05);
  }
  .cal-week-view .cal-header.cal-today,
  .cal-month-view .cal-day-cell.cal-today {
    background-color: rgba(mat.get-color-from-palette($primary), 0.4);
  }
  .cal-day-view .cal-hour:nth-child(odd) {
    background-color: inherit;
  }
  .cal-event-action i {
    margin: 4px;
  }

  /* ngx-pagination */
  .gradus-pagination .ngx-pagination {
    margin-bottom: 0;
    padding: 4px;
    .current {
      background-color: mat.get-color-from-palette($primary);
    }
    a:hover,
    button:hover {
      background: rgba(mat.get-color-from-palette($primary), 0.2);
      color: mat.get-color-from-palette($foreground, base);
    }
  }

  /* ngx-quill */
  .ql-snow .ql-picker,
  .ql-editor.ql-blank::before {
    color: mat.get-color-from-palette($foreground, text);
  }
  .ql-snow .ql-stroke {
    stroke: mat.get-color-from-palette($foreground, text);
  }
  .ql-snow .ql-fill {
    fill: mat.get-color-from-palette($foreground, text);
  }
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background: mat.get-color-from-palette($background, app-bar);
  }

  button.mat-raised-button.mat-primary {
    color: mat.get-color-from-palette($foreground);
  }

  .mat-button-toggle {
    /*background-color: #ffffff;*/
    color: mat.get-color-from-palette($foreground, text);
  }
  .mat-select-panel {
    .mat-option {
      &.mat-selected {
        background: rgba(mat.get-color-from-palette($primary), 0.7) !important;
        color: mat.get-color-from-palette(
          $primary,
          default-contrast
        ) !important;
      }
    }
  }
  .mat-focused .mat-form-field-underline .mat-form-field-ripple {
    background: mat.get-color-from-palette($primary);
  }
  .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($primary);
    color: mat.get-color-from-palette($primary, default-contrast);
  }

  .mat-menu-item {
    &:hover {
      transition: 0.2s;
      background: rgba(mat.get-color-from-palette($primary), 0.7) !important;
    }
    // .mat-icon {
    //   color: mat.get-color-from-palette($primary, "500-contrast") !important;
    // }
  }
  .mat-expansion-panel-header:hover {
    background-color: mat.get-color-from-palette($primary) !important;
    color: mat.get-color-from-palette($primary, default-contrast) !important;
  }
  .mat-expansion-panel-header.mat-expanded:focus,
  .mat-expansion-panel-header.mat-expanded:hover {
    background-color: mat.get-color-from-palette($background, background);
  }
  .gridster {
    background-color: mat.get-color-from-palette($background, background);
  }
  .gridster-row {
    border-top: 1px solid #5f5f5f !important;
    border-bottom: 1px solid #5f5f5f !important;
  }
  .gridster-column {
    border-left: 1px solid #5f5f5f !important;
    border-right: 1px solid #5f5f5f !important;
  }
  .chart-item {
    // color: mat.get-color-from-palette($primary) !important;
    background: mat.get-color-from-palette($background, background) !important;
  }
  .gridster-item {
    box-shadow: 1px 1px 4px 0px rgba(mat.get-color-from-palette($primary), 0.3);
    margin: 2px;

    border-radius: 7px !important;
    .chart-title-holder {
      background: mat.get-color-from-palette($primary);
      color: mat.get-color-from-palette($primary, "500-contrast");
    }
    .chart-container {
      .chart-button {
        color: mat.get-color-from-palette($primary, "500-contrast");
      }
    }
    .chart-container-widget {
      .chart-button {
        color: mat.get-color-from-palette($primary);
      }
    }
  }
  .widget {
    color: mat.get-color-from-palette($primary, default-contrast) !important;
    background: transparent !important;
  }
  .table-element-row:hover {
    background: rgba(mat.get-color-from-palette($primary), 0.7) !important;
    transition: 0.2s;
    .mat-cell,
    .mat-footer-cell {
      color: mat.get-color-from-palette($primary, "500-contrast") !important;
    }
    .mat-primary {
      color: mat.get-color-from-palette($primary, "500-contrast") !important;
    }
    .mat-icon {
      color: mat.get-color-from-palette($primary, "500-contrast") !important;
    }
  }
  .sub-menu {
    border-left: 3px mat.get-color-from-palette($primary) solid;
    // background-color: $dvv-bg;
  }
  .mat-checkbox-background {
    background-color: mat.get-color-from-palette($primary) !important;
  }

  //################################### dashboard

  .ngx-json-viewer .segment .segment-main .segment-key {
    color: mat.get-color-from-palette($primary) !important;
  }
  .chart-object .ngx-json-viewer {
    overflow: auto !important;
    margin-left: -8px !important;
  }
  // input:-webkit-autofill,
  // input:-webkit-autofill:hover,
  // input:-webkit-autofill:focus,
  // textarea:-webkit-autofill,
  // textarea:-webkit-autofill:hover,
  // textarea:-webkit-autofill:focus,
  // select:-webkit-autofill,
  // select:-webkit-autofill:hover,
  // select:-webkit-autofill:focus {
  // }
}
