//default scrollbar
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0 none #ffffff;
    border-radius: 0;
    &:hover {
      background: #cccccc;
    }
    &:active {
      background: #888888;
    }
  }
  ::-webkit-scrollbar-track {
    background: #666666;
    border: 0 none #ffffff;
    border-radius: 0;
    &:hover {
      background: #666666;
    }
    &:active {
      background: #333333;
    }
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  [hidden] {
    display: none;
  }
