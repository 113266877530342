//material components
.mat-card {
  overflow: hidden;
  &.custom-card {
    .mat-card-header {
      margin: -24px -24px 0;
    }
    .mat-card-actions {
      padding-left: 6px;
      padding-right: 6px;
    }
  }
}
.mat-list .mat-list-item,
.mat-nav-list .mat-list-item,
.mat-selection-list .mat-list-item,
.mat-menu-item {
  font-size: 14px !important;
}

.mat-drawer-container {
  height: 100%;
}
.mat-paginator-page-size-select {
  width: 60px !important;
}

.vertical-tabs.mat-tab-group {
  flex-direction: row;
  .mat-tab-labels {
    flex-direction: column;
  }
  .mat-ink-bar {
    display: none;
  }
  .mat-tab-header {
    border: none !important;
  }
  .mat-tab-body-wrapper {
    padding: 0 16px;
  }
}
.mat-button-toggle {
  border: 1px solid #c5c5c5;
}

.app {
  &.fixed-header {
    .mat-drawer-content {
      overflow: unset;
    }
  }
  &.horizontal-menu {
    .inner-sidenav-content {
      padding-top: $toolbar-height + $inner-sidenav-content-padding;
      min-height: calc(
        100% -
          (
            #{$toolbar-height} + #{$horizontal-menu-height} + #{$inner-sidenav-content-padding} *
              2
          )
      );
    }
    .chat-sidenav-list {
      height: calc(
        100vh -
          (
            #{$toolbar-height} + #{$horizontal-menu-height} + #{$inner-sidenav-content-padding} *
              2 + 132px
          )
      );
    }
    .chat-content {
      height: calc(
        100vh -
          (
            #{$toolbar-height} + #{$horizontal-menu-height} + #{$inner-sidenav-content-padding} *
              2 + 238px
          )
      );
    }
    .mailbox-sidenav-list,
    .mailbox-content {
      height: calc(
        100vh -
          (
            #{$toolbar-height} + #{$horizontal-menu-height} + #{$inner-sidenav-content-padding} *
              2 + 132px
          )
      );
    }
    .options-icon {
      top: 126px;
    }
    &.compact {
      .horizontal-menu-item {
        .mat-button {
          font-size: 14px;
          padding-top: 8px;
          padding-bottom: 8px;
          .horizontal-menu-title {
            line-height: 20px;
          }
          .horizontal-menu-icon {
            margin: 0;
          }
        }
        .mat-button-wrapper {
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .horizontal-sub-menu {
          .mat-button {
            flex-direction: row;
            justify-content: flex-start !important;
            align-items: center;
            .horizontal-menu-icon {
              margin-right: 10px;
            }
          }
          .mat-button-wrapper {
            padding: 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          }
        }
      }
    }
    &.mini {
      .horizontal-menu-item {
        .mat-button {
          padding-top: 8px;
          padding-bottom: 8px;
          min-width: 56px;
          .horizontal-menu-title {
            display: none;
          }
          .horizontal-menu-icon {
            margin: 0;
            font-size: 36px;
            height: 36px;
            width: 36px;
          }
        }
        .horizontal-sub-menu {
          width: 68px;
          .mat-button {
            height: $horizontal-menu-height;
          }
        }
      }
    }
  }
  &.compact {
    .sidenav {
      width: 160px;
    }
    .user-block {
      .user-info-wrapper {
        margin: 0;
      }
      img {
        margin: 5px;
      }
    }
    .menu-item {
      .mat-button {
        font-size: 14px;
        padding-top: 8px;
        padding-bottom: 8px;
        .menu-title {
          line-height: 24px;
        }
      }
      .mat-button-wrapper {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .menu-icon {
      margin: 0;
      font-size: 36px;
      height: 36px;
      width: 36px;
    }
    .menu-expand-icon {
      top: 28px;
    }
    .sub-menu {
      .mat-button {
        padding-left: 0 !important;
      }
    }
  }
  &.mini {
    .sidenav {
      width: 70px;
    }
    .sidenav-menu-outer.user-block-show {
      height: calc(
        100vh - #{$toolbar-height + $mini-sidenav-user-block-height}
      );
    }
    .user-block {
      img {
        width: 52px !important;
      }
      .user-info-wrapper {
        .user-info {
          p {
            display: none;
          }
        }
      }
      &.show {
        height: $mini-sidenav-user-block-height;
      }
    }
    .menu-item {
      .mat-button {
        padding-top: 8px;
        padding-bottom: 8px;
        min-width: 70px;
        .menu-title {
          line-height: 24px;
        }
      }
      .mat-button-wrapper {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .menu-title {
        display: none;
      }
    }
    .menu-icon {
      margin: 0;
      font-size: 36px;
      height: 36px;
      width: 36px;
    }
    .menu-expand-icon {
      top: 14px;
      right: 0;
    }
    .sub-menu {
      .mat-button {
        padding-left: 0 !important;
      }
    }
  }
  .window-button {
    height: 20px;
    width: 20px;
    line-height: 0;
    bottom: 28px;
    left: 21px;
    .mat-button-wrapper {
      line-height: 0 !important;
      height: 20px;
      width: 20px;
      padding: 0;
    }
    .window-button-ico {
      height: 20px;
      width: 20px;
      font-size: 20px;
    }
    .mat-button-focus-overlay {
      background-color: transparent !important;
    }
  }
  .dialog-button {
    top: 21px;
    .right {
      right: 21px;
    }
    .left {
      left: 21px;
    }
  }
}

// Dialog
.mat-dialog-container {
  -webkit-user-select: none;
  overflow-x: hidden;
  min-width: 450px;
  padding: 32px !important;

  .mat-dialog-title {
    font-size: x-large;
  }

  .mat-form-field,
  .dialog-form {
    width: 100%;
  }
}
